import React, { useEffect} from "react"; // eslint-disable-next-line
import { Col, Container, Image, Row, Breadcrumb, NavLink } from "react-bootstrap";
import OwlCarousel from 'react-owl-carousel';
import Layout from "./layout.js";
import { seo } from "../seo.js";
import quote from "../images/quote.png";
import { HOME_PAGE_URL } from "../constants.js";
import { Link } from "react-router-dom";

export default function SuccessStories() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    
    useEffect(() => {
        seo({
          title: "Success Stories",
        });
    }, []);

    return (
        <Layout>
            
            <section className="breadcrumb-bg">
                <Container>
                    <h1 className="breadcrumb-title">Success Stories</h1>
                    <Breadcrumb className="mt-2">
                        <Breadcrumb.Item><Link as={NavLink} to={HOME_PAGE_URL}>Home</Link></Breadcrumb.Item>
                        <Breadcrumb.Item active>Success Stories</Breadcrumb.Item>
                    </Breadcrumb>
                </Container>
            </section>

            <section className="section-padding">
                <Container>
                    <Row className="ss-testimonials mb-5">
                        <Col lg={9} className="ss-single-slides">
                            <div className="success-stories-block">
                                <OwlCarousel className="owl-theme"
                                    loop={true} 
                                    autoplay ={true}
                                    center={true}
                                    dots={false}
                                    responsive={{
                                        '0': { items: 1, nav: false},
                                        '768': { items: 1, nav: false},
                                        '992': { items: 1, nav: false}
                                    }}>
                                    <div className='single-item success-stories'>
                                        <div className="ss-client-details">
                                            <div className="ss-client-detail">
                                                <div className="ss-testimonial-student">
                                                    <h5 className="mb-0">Mohammad</h5>
                                                    <div className="ratings mb-0 mt-0">
                                                        <span>5.0</span>
                                                        <i className="fi fi-sr-star"></i>
                                                        <i className="fi fi-sr-star"></i>
                                                        <i className="fi fi-sr-star"></i>
                                                        <i className="fi fi-sr-star"></i>
                                                        <i className="fi fi-sr-star"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <p className="ss-testimonial-content">I passed PCE exam in first attempt and  its never be possible without My guide, teacher Mamta Kansara. I pass this exam from India --initially I was very much confuse how to take exam from where to prepare but after contacting Global Physio and Mamta it went smoothly with all the process to the day, hour and minute of exam. A to Z topics about PCE   written exam is covered from Mamta Kansara. Her live lectures help me a lot and her way of teaching is excellent for every concepts it clears all my doubts. Mock exams from her help me lot to pass this exam. ----MOST RECOMMENDED AND TRULY GUIDED ACADEMY.</p>
                                        </div>
                                    </div>
                                    <div className='single-item success-stories'>
                                        <div className="ss-client-details">
                                            <div className="ss-client-detail">
                                                <div className="ss-testimonial-student">
                                                    <h5 className="mb-0">Nikita</h5>
                                                <div className="ratings mb-0 mt-0">
                                                    <span>5.0</span>
                                                    <i className="fi fi-sr-star"></i>
                                                    <i className="fi fi-sr-star"></i>
                                                    <i className="fi fi-sr-star"></i>
                                                    <i className="fi fi-sr-star"></i>
                                                    <i className="fi fi-sr-star"></i>
                                                </div>
                                                </div>
                                            </div>
                                            <p className="ss-testimonial-content">"Self-belief and Hard work will always earn you success." I am extremely happy to announce that I cleared PCE written component. Yes, the word “PASSED” seems like a miracle when it comes after long wait and hard work. It wouldn't have been possible without proper guidance of Mamta Kansara. All the lectures, materials, notes and exams helped a lot to cover the contents. She always helped me identifying my weakness and pushed me to work on it. And that's the only reason I could finally make it to the shining path. Thank you for always being there for me, you master mind---ONE AND ONLY MAMTA KANSARA.</p>
                                        </div>
                                    </div>
                                </OwlCarousel>
                            </div>
                        </Col>
                        <Col lg={3}>
                            <div className="student-success-stories">
                                <h3 className="text-center">Student Success Stories</h3>
                            </div>
                        </Col>
                    </Row>
                    <Row data-masonry='{{"percentPosition": true }}' className="success-story-masonry">
                        <Col lg={4} md={6}className="masonry-column masonry-column-1">
                            <div className='item'>
                                <p className="testimonial-content">I passed PCE exam in first attempt and  its never be possible without My guide, teacher Mamta Kansara. I pass this exam from India --initially I was very much confuse how to take exam from where to prepare but after contacting Global Physio and Mamta it went smoothly with all the process to the day, hour and minute of exam. A to Z topics about PCE   written exam is covered from Mamta Kansara. Her live lectures help me a lot and her way of teaching is excellent for every concepts it clears all my doubts. Mock exams from her help me lot to pass this exam. ----MOST RECOMMENDED AND TRULY GUIDED ACADEMY.</p>
                                <div className="client-details mt-3">
                                    <span className="name-initial">M</span>
                                    <div className="client-detail">
                                        <h5>Mohammad</h5>
                                        <div className="ratings">
                                            <span>5.0</span>
                                            <i className="fi fi-sr-star"></i>
                                            <i className="fi fi-sr-star"></i>
                                            <i className="fi fi-sr-star"></i>
                                            <i className="fi fi-sr-star"></i>
                                            <i className="fi fi-sr-star"></i>
                                        </div>
                                    </div>
                                </div>
                                <Image src={quote} alt="quote" className="quote" />
                            </div>
                            <div className='item'>
                                <p className="testimonial-content">I was desperately waiting for to see only 4 letters ‘‘Pass”. By god’s grace and blessings of my family finally I passed PCE. The biggest role played by Mamta Kansara ma'am who was not only my teacher but also she was my guide, friend, motivator. She is one woman army. Her live lectures are so helpful. Also I had to be ready for her rapid fire questions at any time but that kind of revision played vital role in my revision. On top of that her practice exams, ethics classes and daily questions are very helpful. She was the one I can call her any time and she answered my questions all the times.---THANK YOU FOR EVERYTHING.</p>
                                <div className="client-details mt-3">
                                    <span className="name-initial">T</span>
                                    <div className="client-detail">
                                        <h5>Trusha</h5>
                                        <div className="ratings">
                                            <span>5.0</span>
                                            <i className="fi fi-sr-star"></i>
                                            <i className="fi fi-sr-star"></i>
                                            <i className="fi fi-sr-star"></i>
                                            <i className="fi fi-sr-star"></i>
                                            <i className="fi fi-sr-star"></i>
                                        </div>
                                    </div>
                                </div>
                                <Image src={quote} alt="quote" className="quote" />
                            </div>
                            <div className='item'>
                                <p className="testimonial-content">With the blessings of God, I successfully passed my PCE written component on third attempt. I want to express my heartfelt gratitude to Mamta Kansara for her invaluable support in achieving this milestone. I found that ma’am had answers to all my questions always and this made me believe she is a walking Encyclopedia for sure. I realized that her concepts are backed up with evidence from textbooks. This being my third attempt, I always felt anxious and stressed about the exams, however, the way she counselled me and guided me through the process help me not only prepare well but also keep my calm during the exam. It was due to the confidence she instilled in me that I was able to complete my paper before time.</p>
                                <div className="client-details mt-3">
                                    <span className="name-initial">R</span>
                                    <div className="client-detail">
                                        <h5>Rohit</h5>
                                        <div className="ratings">
                                            <span>5.0</span>
                                            <i className="fi fi-sr-star"></i>
                                            <i className="fi fi-sr-star"></i>
                                            <i className="fi fi-sr-star"></i>
                                            <i className="fi fi-sr-star"></i>
                                            <i className="fi fi-sr-star"></i>
                                        </div>
                                    </div>
                                </div>
                                <Image src={quote} alt="quote" className="quote" />
                            </div>
                        </Col>
                        <Col lg={4} md={6} sm={12} className="masonry-column">
                            <div className='item'>
                                <p className="testimonial-content">"Self-belief and Hard work will always earn you success." I am extremely happy to announce that I cleared PCE written component. Yes, the word “PASSED” seems like a miracle when it comes after long wait and hard work. It wouldn’t have been possible without proper guidance of Mamta Kansara. All the lectures, materials, notes and exams helped a lot to cover the contents. She always helped me identifying my weakness and pushed me to work on it. And that’s the only reason I could finally make it to the shining path. Thank you for always being there for me, you master mind---ONE AND ONLY MAMTA KANSARA.</p>
                                <div className="client-details mt-3">
                                    <span className="name-initial">N</span>
                                    <div className="client-detail">
                                        <h5>Nikita</h5>
                                        <div className="ratings">
                                            <span>5.0</span>
                                            <i className="fi fi-sr-star"></i>
                                            <i className="fi fi-sr-star"></i>
                                            <i className="fi fi-sr-star"></i>
                                            <i className="fi fi-sr-star"></i>
                                            <i className="fi fi-sr-star"></i>
                                        </div>
                                    </div>
                                </div>
                                <Image src={quote} alt="quote" className="quote" />
                            </div>
                            <div className='item'>
                                <p className="testimonial-content">Finally my time to write the story passed my PCE exam. I would like to thank to Global Physio Academy and Mamta ma'am who was there for me all the time supporting me personally and professionally. Her lectures are very well explained in details and she is just phone call away whenever I needed her, multiple revisions and never said no to anything. Thank you once again it was not possible without you.-PCE WRITTEN AND I THINK PCE WITH MAMTA KANSARA IS BOTH SYNONYMOUS.</p>
                                <div className="client-details mt-3">
                                    <span className="name-initial">P</span>
                                    <div className="client-detail">
                                        <h5>Pooja</h5>
                                        <div className="ratings">
                                            <span>5.0</span>
                                            <i className="fi fi-sr-star"></i>
                                            <i className="fi fi-sr-star"></i>
                                            <i className="fi fi-sr-star"></i>
                                            <i className="fi fi-sr-star"></i>
                                            <i className="fi fi-sr-star"></i>
                                        </div>
                                    </div>
                                </div>
                                <Image src={quote} alt="quote" className="quote" />
                            </div>
                            <div className='item'>
                                <p className="testimonial-content">After experiencing the disappointment of failing my important license exam on the first try, I channeled my determination into rigorous preparation. With focused study and mentorship from Global Physio Academy, I honed my understanding of the material. With continued support of my family and the proper guidance from Mamta Ma’am and her discussion sessions, I succeeded in my second attempt with flying colours.</p>
                                <div className="client-details mt-3">
                                    <span className="name-initial">J</span>
                                    <div className="client-detail">
                                        <h5>Jesica</h5>
                                        <div className="ratings">
                                            <span>5.0</span>
                                            <i className="fi fi-sr-star"></i>
                                            <i className="fi fi-sr-star"></i>
                                            <i className="fi fi-sr-star"></i>
                                            <i className="fi fi-sr-star"></i>
                                            <i className="fi fi-sr-star"></i>
                                        </div>
                                    </div>
                                </div>
                                <Image src={quote} alt="quote" className="quote" />
                            </div>
                        </Col>
                        <Col lg={4} md={6} sm={12} className="masonry-column ss-column">
                            <div className='item'>
                                <p className="testimonial-content">Now is my time to shine! I can finally announce to the world that I am a resident physical therapist, and the best part is that I passed on my first try. Mamta Ma'am was crucial in assisting me in achieving a major life objective during my  exam preparation process. So, ma'am, please accept my sincere gratitude for your tireless efforts and unceasing commitment on my behalf. I can well recall the time when I wanted to take a test but had not yet begun my preparation. You are the one who inspired me and made me feel confident that even I could succeed! Despite the fact that I called you at 11:00 PM with an endless list of questions, you never seemed unhappy, and you always provided an answer. You are not only my instructor; you are also a part of my family who I can rely on without hesitation. When necessary, you were able to be tough, but you were also kind and compassionate.---EVERYONE MUST STOP AT GLOBAL PHYSIO TO CLEAR PCE WRITTEN EXAM.</p>
                                <div className="client-details mt-3">
                                    <span className="name-initial">D</span>
                                    <div className="client-detail">
                                        <h5>Dhara</h5>
                                        <div className="ratings">
                                            <span>5.0</span>
                                            <i className="fi fi-sr-star"></i>
                                            <i className="fi fi-sr-star"></i>
                                            <i className="fi fi-sr-star"></i>
                                            <i className="fi fi-sr-star"></i>
                                            <i className="fi fi-sr-star"></i>
                                        </div>
                                    </div>
                                </div>
                                <Image src={quote} alt="quote" className="quote" />
                            </div>
                            <div className='item'>
                                <p className="testimonial-content">With proud moment and by God's grace I proudly say we met MAMTA KANSARA--GREAT PERSONALITY AND EVERREADY BATTERY WOMAN. The moment we started our preparation until our exam she is all time present beside us with only 1 phone call away. When I see PASS on my screen and that not only me ---my wife also pass the exam--both of us clear with first attempt. ---WONDERFUL ACHIEVEMENT. If I am writing all her capability perhaps it takes few pages to write--so much clear idea, way of preparation and her too the point lecture prepares us with clear concept. We have not enough words for her constant support and motivation all throughout our journey. MSK--NEURO--CARDIO--PULMONARY--OTHER SYSTEM--PEDIA--ETHICS--ALL IN ONE---PCE WITH MAMTA. It is so easy to be with 1 person who teaches us, guides us and knows our capacity where we are! MENTORING----BEST portion of her class which makes us to think with co-relation of topic. GLOBAL PHYSIO==MAMTA KANSARA==PASSED PCE WRITTEN FOR US.</p>
                                <div className="client-details mt-3">
                                    <span className="name-initial">M</span>
                                    <div className="client-detail">
                                        <h5>Mohan and Nisha</h5>
                                        <div className="ratings">
                                            <span>5.0</span>
                                            <i className="fi fi-sr-star"></i>
                                            <i className="fi fi-sr-star"></i>
                                            <i className="fi fi-sr-star"></i>
                                            <i className="fi fi-sr-star"></i>
                                            <i className="fi fi-sr-star"></i>
                                        </div>
                                    </div>
                                </div>
                                <Image src={quote} alt="quote" className="quote" />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

        </Layout>
    );
}