import React, { useEffect} from "react"; // eslint-disable-next-line
import { Col, Container, Image, Row, Breadcrumb, Table, NavLink } from "react-bootstrap";
import Layout from "./layout.js";
import check from "../images/check.svg";
import dash from "../images/dash.svg";
import GetStartedModal from "../components/get-started-modal.js";
import { seo } from "../seo.js";
import { HOME_PAGE_URL } from "../constants.js";
import angle_small_right from "../images/angle-small-right.svg"
import { Link } from "react-router-dom";

export default function PceWrittenPreparation() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        seo({
          title: "PCE Written Preparation",
        });
    }, []);

    return (
        <Layout>
            
            <section className="breadcrumb-bg">
                <Container>
                    <h1 className="breadcrumb-title">PCE Written Preparation</h1>
                    <Breadcrumb className="mt-2">
                        <Breadcrumb.Item><Link as={NavLink} to={HOME_PAGE_URL}>Home</Link></Breadcrumb.Item>
                        <Breadcrumb.Item className="hide-cursor">Courses</Breadcrumb.Item>
                        <Breadcrumb.Item active>PCE Written Preparation</Breadcrumb.Item>
                    </Breadcrumb>
                </Container>
            </section>
            
            <section className="section-padding">
                <Container>
                    <Row>
                        <Col className="pce-written-prep">
                            <span>Global Physio provide full course only with complete theoretical and mentoring bundle.</span>
                            <span>We believe in quality of education where everything is important to clear PCE Written exam.</span>
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        <Col>
                            <Table responsive bordered>
                                <thead>
                                    <tr>
                                        <th className="empty-th"></th>
                                        <th className="text-center pwp-course">Course</th>
                                        <th className="text-center pwp-mentoring">Mentoring</th>
                                        <th className="text-center pwp-ethic-bundle">Ethic Bundle</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="table-active">Detailed study schedule</td>
                                        <td className="text-center"><Image src={check} alt="Check" /></td>
                                        <td className="text-center"><Image src={dash} alt="Uncheck" /></td>
                                        <td className="text-center"><Image src={dash} alt="Uncheck" /></td>
                                    </tr>
                                    <tr>
                                        <td className="table-active">60 plus live lectures-detailed theory as per blueprint</td>
                                        <td className="text-center"><Image src={check} alt="Check" /></td>
                                        <td className="text-center"><Image src={dash} alt="Uncheck" /></td>
                                        <td className="text-center"><Image src={dash} alt="Uncheck" /></td>
                                    </tr>
                                    <tr>
                                        <td className="table-active">Recordings for revisions</td>
                                        <td className="text-center"><Image src={check} alt="Check" /></td>
                                        <td className="text-center"><Image src={dash} alt="Uncheck" /></td>
                                        <td className="text-center"><Image src={dash} alt="Uncheck" /></td>
                                    </tr>
                                    <tr>
                                        <td className="table-active">Materials for reference</td>
                                        <td className="text-center"><Image src={check} alt="Check" /></td>
                                        <td className="text-center"><Image src={dash} alt="Uncheck" /></td>
                                        <td className="text-center"><Image src={dash} alt="Uncheck" /></td>
                                    </tr>
                                    <tr>
                                        <td className="table-active">Ethics bundles -10 lectures with mini ethics mock test</td>
                                        <td className="text-center"><Image src={check} alt="Check" /></td>
                                        <td className="text-center"><Image src={dash} alt="Uncheck" /></td>
                                        <td className="text-center"><Image src={check} alt="Check" /></td>
                                    </tr>
                                    <tr>
                                        <td className="table-active">Periodically mini exam - content review</td>
                                        <td className="text-center"><Image src={check} alt="Check" /></td>
                                        <td className="text-center"><Image src={dash} alt="Uncheck" /></td>
                                        <td className="text-center"><Image src={dash} alt="Uncheck" /></td>
                                    </tr>
                                    <tr>
                                        <td className="table-active">Student's feedback to focus on everyone strength and weakness</td>
                                        <td className="text-center"><Image src={check} alt="Check" /></td>
                                        <td className="text-center"><Image src={dash} alt="Uncheck" /></td>
                                        <td className="text-center"><Image src={dash} alt="Uncheck" /></td>
                                    </tr>
                                    <tr>
                                        <td className="table-active">2 MOCK test with rationales (Also in mentoring)</td>
                                        <td className="text-center"><Image src={check} alt="Check" /></td>
                                        <td className="text-center"><Image src={check} alt="Check" /></td>
                                        <td className="text-center"><Image src={dash} alt="Uncheck" /></td>
                                    </tr>
                                    <tr>
                                        <td className="table-active">Complete focus with questions and answers with co-relation of topics</td>
                                        <td className="text-center"><Image src={check} alt="Check" /></td>
                                        <td className="text-center"><Image src={check} alt="Check" /></td>
                                        <td className="text-center"><Image src={dash} alt="Uncheck" /></td>
                                    </tr>
                                    <tr>
                                        <td className="table-active">Analysis of student's knowledge about topics</td>
                                        <td className="text-center"><Image src={check} alt="Check" /></td>
                                        <td className="text-center"><Image src={check} alt="Check" /></td>
                                        <td className="text-center"><Image src={dash} alt="Uncheck" /></td>
                                    </tr>
                                    <tr>
                                        <td className="table-active">Detailed explanation of topic concept</td>
                                        <td className="text-center"><Image src={check} alt="Check" /></td>
                                        <td className="text-center"><Image src={check} alt="Check" /></td>
                                        <td className="text-center"><Image src={dash} alt="Uncheck" /></td>
                                    </tr>
                                    <tr>
                                        <td className="table-active">Weekly test for given topics</td>
                                        <td className="text-center"><Image src={check} alt="Check" /></td>
                                        <td className="text-center"><Image src={check} alt="Check" /></td>
                                        <td className="text-center"><Image src={dash} alt="Uncheck" /></td>
                                    </tr>
                                    <tr>
                                        <td className="table-active">Work for Domain of PCE Exam as per blue print</td>
                                        <td className="text-center"><Image src={check} alt="Check" /></td>
                                        <td className="text-center"><Image src={check} alt="Check" /></td>
                                        <td className="text-center"><Image src={dash} alt="Uncheck" /></td>
                                    </tr>
                                    <tr>
                                        <td className="table-active">4 months mentoring with limited students</td>
                                        <td className="text-center"><Image src={check} alt="Check" /></td>
                                        <td className="text-center"><Image src={check} alt="Check" /></td>
                                        <td className="text-center"><Image src={dash} alt="Uncheck" /></td>
                                    </tr>
                                    <tr>
                                        <td className="table-active">Enhance students learning with live discussion through questions and answers</td>
                                        <td className="text-center"><Image src={check} alt="Check" /></td>
                                        <td className="text-center"><Image src={check} alt="Check" /></td>
                                        <td className="text-center"><Image src={dash} alt="Uncheck" /></td>
                                    </tr>
                                    <tr>
                                        <td className="table-active">Final content review classes</td>
                                        <td className="text-center"><Image src={check} alt="Check" /></td>
                                        <td className="text-center"><Image src={check} alt="Check" /></td>
                                        <td className="text-center"><Image src={dash} alt="Uncheck" /></td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col className="">
                            <h3 className="mb-4 pce-description">The PCE Written course is divided into two component.</h3>
                            <div className="gpa-components mb-3">
                                <Image src={angle_small_right} alt="First component" />
                                <p><strong>First component</strong> is theoretical classes which is 3 to 4 months program. It consist of more than 60 live lectures as per CAPR Blueprint with recordings of videos for revision. Ethics 10 classes with more than 200 questions which is ethics-based scenario (PCE Ethics and NPTE Ethics has different class schedules as per different rules and regulations).</p>
                            </div>
                            <div className="gpa-components mb-4">
                                <Image src={angle_small_right} alt="Second component" />
                                <p><strong>Second component</strong> is Mentoring classes which contain hundreds of CAPR style questions and answers with rational and co-relation of several topics. 2 vignette style MOCK test for PCE WRITTEN EXAM which prepare students for written exam with full confidence.</p>
                            </div>
                            <div className="sub-course-modal text-center">
                                <GetStartedModal />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

        </Layout>
    );
}