import React, { useEffect, useState } from "react";
import { Button, Form, FormGroup } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
import { useForm } from "react-hook-form";
import axios from "axios";
import * as Constants from '../constants';
import { ToastContainer, toast } from "react-toastify";

export default function GetStartedModal() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => { reset(); setInputMobileValue(""); setShow(true) };

  const client = axios.create({
    baseURL: Constants.BACKEND_API_URL,
  });

const [inputMobileValue, setInputMobileValue] = useState("");

const handleMobileInputChange = (e) => {
    const value = e.target.value;
    const numericValue = value.trim().replace(/[^ +\d]/g, "");
    setInputMobileValue(numericValue);
  };

  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
     if (isLoading) {
      document.body.classList.add("loader-showing");
    } else {
      document.body.classList.remove("loader-showing");
    }
  }, [isLoading]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const onEnquirySubmit = (data) => {
    //console.log(data)
    setIsLoading(true);

    data = { ...data, record_type: 'get started' };

    const headers = {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
    };

    client.post(
        "send-mail.php",
        { ...data, record_type: 'enquiry' },
        { headers: headers }
    ).then((response) => {
        // Handle the response from the server if needed
        setIsLoading(false);
      
        if (response.data.status_code == 1) {
          toast.success(response.data.message);
          reset();
          setInputMobileValue("");
          setShow(false);
          
        } else {
          toast.error(response.data.message);
        }
    }).catch((error) => {
        // Handle errors if the request fails
        toast.error(error);
    });
  };

  return (
    <>
      <Button variant="primary" onClick={handleShow}>Get Started</Button>

      <Modal show={show} onHide={handleClose} size="md" className="get-started-modal">
      {isLoading && (
        <div className="loader">
            <div className="loader--ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
         </div>
      )}
        <Modal.Header closeButton>
          <Modal.Title className='get-started-subtext'>Global Physio Academy <span className="get-started-main-title">Get Started</span></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(onEnquirySubmit)} id="enquiry-form" method="post">
            <FormGroup className="mb-2">
                <Form.Label>Name<span className="text-danger">*</span></Form.Label>
                <Form.Control type="text" placeholder="Name" name="name" {...register("name", { required: true , setValueAs: value => value.trim() })}></Form.Control>
                {errors.name && (
                    <span className="text-danger">
                        Please Enter Name.
                    </span>
                    )}
            </FormGroup>
            <FormGroup className="mb-2">
              <Form.Label>Mobile<span className="text-danger">*</span></Form.Label>
              <Form.Control type="tel" placeholder="Mobile"
              minLength="8"
              maxLength="15"
              value={inputMobileValue}
              name="mobile"
              {...register("mobile", {
                required: "Please Enter Mobile.",
              })}
              onChange={handleMobileInputChange}
              ></Form.Control>
              {errors.mobile && (
                  <span className="text-danger">
                  {errors.mobile?.message}
                  </span>
              )}
          </FormGroup>
          <FormGroup className="mb-2">
              <Form.Label>Email<span className="text-danger">*</span></Form.Label>
              <Form.Control type="email" placeholder="Email" 
              name="email123"
              {...register("email", {
                  required: "Please Enter Email.",
                  pattern: {
                    value:
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: "Please Enter Valid Email.",
                  },
                })}
              ></Form.Control>
              {errors.email && (
                  <p className="mb-0 text-danger">
                      {errors.email?.message}
                  </p>
                  )}
          </FormGroup>
              <Form.Group className="mb-4">
                <Form.Label>Credential Status</Form.Label>
                <Form.Select name="credential_status" {...register("credential_status")}>
                    <option defaultValue="Select">Select</option>
                    <option value="completed">Completed</option>
                    <option value="pending">Pending</option>
                </Form.Select>
            </Form.Group>
            <FormGroup className="mb-2 d-none">
	            <Form.Label>Webname</Form.Label>
	            <Form.Control type="text" placeholder="Webname" name="webname" {...register("webname", { required: false })}></Form.Control>
	        </FormGroup>
            <FormGroup className="popup-btns">
              <Button variant="secondary" className="close-btn" onClick={handleClose}>Close</Button>
              <Button variant="primary" type="submit" className="float-end">Submit</Button>
            </FormGroup>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}