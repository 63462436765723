import React, { useEffect, useState } from "react";
import { Button, Form, FormGroup } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
import { useForm } from "react-hook-form";
import axios from "axios";
import * as Constants from '../constants';
import { ToastContainer, toast } from "react-toastify";
import { Link } from "react-router-dom";

export default function Download20QuestionsModal() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => { reset();  setShow(true) };

  const client = axios.create({
    baseURL: Constants.BACKEND_API_URL,
  });

  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
     if (isLoading) {
      document.body.classList.add("loader-showing");
    } else {
      document.body.classList.remove("loader-showing");
    }
  }, [isLoading]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const onEnquirySubmit = (data) => {
    //console.log(data)
    setIsLoading(true);

    

    const headers = {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        'Accept': 'application/pdf'
       
    };

    client.post(
        "send-mail.php",
        { ...data, record_type: 'sample Quiz Request' },
        { headers: headers }
    ).then((response) => {
        // Handle the response from the server if needed
        setIsLoading(false);
      
        if (response.data.status_code == 1) {
          toast.success(response.data.message);
          reset();
          setShow(false);
          const link = document.createElement('a');
          link.href = response.data.pdfinfo;
          link.setAttribute('download', response.data.pdfName ); //or any other extension
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          
        } else {
          toast.error(response.data.message);
        }
    }).catch((error) => {
        // Handle errors if the request fails
        toast.error(error);
    });
  };

  return (
    <>
      <Link variant="primary" onClick={handleShow} className="questions-download">CLICK HERE</Link>

      <Modal show={show} onHide={handleClose} size="md" className="download-question-modal">
      {isLoading && (
        <div className="loader">
            <div className="loader--ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
         </div>
      )}
        <Modal.Header closeButton>
          <Modal.Title className="download-question-main-title">Download 20 Question PCE Sample Quiz For FREE</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(onEnquirySubmit)} id="download-question-form" method="post">
            <FormGroup className="mb-2">
                <Form.Label>Name<span className="text-danger">*</span></Form.Label>
                <Form.Control type="text" placeholder="Name" name="name" {...register("name", { required: true , setValueAs: value => value.trim() })}></Form.Control>
                {errors.name && (
                    <span className="text-danger">
                        Please Enter Name.
                    </span>
                    )}
            </FormGroup>
            <FormGroup className="mb-2">
                <Form.Label>What PT school did you graduate from?<span className="text-danger">*</span></Form.Label>
                <Form.Control type="text" placeholder="What PT school did you graduate from?" name="graduate" {...register("graduate", { required: true , setValueAs: value => value.trim() })}></Form.Control>
                {errors.graduate && (
                    <span className="text-danger">
                        Please Enter What PT school did you graduate from?
                    </span>
                    )}
            </FormGroup>
          <FormGroup className="mb-4">
              <Form.Label>Email<span className="text-danger">*</span></Form.Label>
              <Form.Control type="email" placeholder="Email" 
              name="email123"
              {...register("email", {
                  required: "Please Enter Email.",
                  pattern: {
                    value:
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: "Please Enter Valid Email.",
                  },
                })}
              ></Form.Control>
              {errors.email && (
                  <p className="mb-0 text-danger">
                      {errors.email?.message}
                  </p>
                  )}
          </FormGroup>
            <FormGroup className="mb-2 d-none">
	            <Form.Label>Webname</Form.Label>
	            <Form.Control type="text" placeholder="Webname" name="webname" {...register("webname", { required: false })}></Form.Control>
	        </FormGroup>
            <FormGroup className="popup-btns download-question-btn">
              <Button variant="primary" type="submit" className="float-end">DOWNLOAD NOW</Button>
            </FormGroup>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}