export const LANDING_PAGE_URL = "/";
export const HOME_PAGE_URL = "/global-physio-academy.html";
export const INDEX_PAGE_URL = "/index.html";
export const ABOUT_US_PAGE_URL = "/about-us.html";
export const CONTACT_US_PAGE_URL = "/contact-us.html";
export const SUCCESS_STORIES_PAGE_URL = "/success-stories.html";
export const PCE_WRITTEN_PREPARATION_PAGE_URL = "/pce-written-preparation.html";
export const NPTE_PREPARATION_PAGE_URL = "/npte-preparation.html";
export const ERROR_404_PAGE_URL = "/404-error";
export const TERMS_OF_SERVICE_PAGE_URL = "/terms-of-services.html";
export const BACKEND_API_URL = "https://www.globalphysioacademy.com/backend/";