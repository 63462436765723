import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { Col, Container, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import footer_logo from "../../images/footer_logo.png";
import whatsapp from "../../images/whatsapp.png";
import { ABOUT_US_PAGE_URL, CONTACT_US_PAGE_URL, HOME_PAGE_URL, NPTE_PREPARATION_PAGE_URL, PCE_WRITTEN_PREPARATION_PAGE_URL, SUCCESS_STORIES_PAGE_URL, TERMS_OF_SERVICE_PAGE_URL } from "../../constants";

export default function Footer() {
    return (
        <>
        <footer className="footer-bg">
            <Container>
                <Row>
                    <Col lg={3} sm={6} className="footer-links mb-4">
                        <Link to={HOME_PAGE_URL} className="mb-2">
                            <Image  src={footer_logo} alt="Global Physio Academy" />
                        </Link>
                        <p className="footer-description mt-3 float-left">Global Physio Academy provides excellent teaching to physiotherapy students for PCE and NPTE exam preparation.</p>
                    </Col>
                    <Col lg={3} sm={6} className="mb-4">
                        <h4 className="footer-title mb-3">Quick Links</h4>
                        <div className="footer-text-seperator"></div>
                        <ul className="quick-links">
                            <li><Link to={HOME_PAGE_URL}><i className="fi fi-rr-angle-small-right"></i> Home</Link></li>
                            <li><Link to={ABOUT_US_PAGE_URL}><i className="fi fi-rr-angle-small-right"></i> About Us</Link></li>
                            <li><Link to={SUCCESS_STORIES_PAGE_URL}><i className="fi fi-rr-angle-small-right"></i> Success Stories</Link></li>
                            <li><Link to={CONTACT_US_PAGE_URL}><i className="fi fi-rr-angle-small-right"></i> Contact Us</Link></li>
                            <li><Link to={TERMS_OF_SERVICE_PAGE_URL}><i className="fi fi-rr-angle-small-right"></i> Terms of Services</Link></li>
                        </ul>
                    </Col>
                    <Col lg={3} sm={6} className="mb-4">
                        <h4 className="footer-title mb-3">Courses</h4>
                        <div className="footer-text-seperator"></div>
                        <ul className="courses">
                            <li><Link to={PCE_WRITTEN_PREPARATION_PAGE_URL}><i className="fi fi-rr-angle-small-right"></i> PCE Written Preparation</Link></li>
                            <li><Link to={NPTE_PREPARATION_PAGE_URL}><i className="fi fi-rr-angle-small-right"></i> NPTE Preparation</Link></li>
                        </ul>
                    </Col>
                    <Col lg={3} sm={6} className="mb-4"> 
                        <h4 className="footer-title mb-3">Contact Us</h4>
                        <div className="footer-text-seperator"></div>
                        <ul className="contact-details">
                            <li><Link to={'tel:+919157884946'}><i className="fi fi-rr-mobile-button"></i> +91 91578 84946</Link></li>
                            <li><Link to={'tel:+14168791986'}><i className="fi fi-rr-mobile-button"></i> +1 416 879 1986</Link></li>
                            <li><Link to={'https://api.whatsapp.com/send?phone=919157884946'} target="_blank" rel="noreferrer"><i className="fi fi-brands-whatsapp"></i> +91 91578 84946</Link></li>
                            <li><Link to={'https://api.whatsapp.com/send?phone=14168791986'} target="_blank" rel="noreferrer"><i className="fi fi-brands-whatsapp"></i> +1 416 879 1986</Link></li>
                        </ul>
                    </Col>
                </Row>
            </Container>
        </footer>
        <section className="bottom-footer">
            <Container>
                <Row>
                    <Col className="bfooter-content">
                        <p>&copy; 2023 - Global Physio Academy. All Rights Reserved.</p>
                        <p>Developed By <Link to={'https://www.thewildtigers.com/'} target="_blank" rel="noopener noreferrer" className="twt">TWT</Link>.</p>
                    </Col>
                </Row>
            </Container>
        </section>
        <div className="whatsapp-float">
            <div className="circle">
                <Link className="link-unstyled cover-a-tag" to={'https://api.whatsapp.com/send?phone=14168791986'} target="_blank" rel="noreferrer">
                    <Image src={whatsapp} alt="Flating WhatsApp" />
                </Link>
            </div>
        </div>
        </>
    );
}