import Header from './layout/header';
import Footer from './layout/footer';

export default function Layout({ children }) {
    let settingInfo = {};
    settingInfo.name = 'welcome';
    settingInfo.site_title = 'React Demo';
    settingInfo.image_path = 'https://www.sunteknovation.com/uploads/admin/settings/logo_1636955993.png';
      return (
      <>
        <Header settingInfo={settingInfo}/>
        <main>{children}</main>
        <Footer />
      </>
    );
}