import React, { useEffect} from "react"; // eslint-disable-next-line
import { Col, Container, Image, Row, Breadcrumb, NavLink } from "react-bootstrap";
import Layout from "./layout.js";
import { seo } from "../seo.js";
import { HOME_PAGE_URL } from "../constants.js";
import { Link } from "react-router-dom";

export default function TermsOfServices() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    
    useEffect(() => {
        seo({
          title: "Terms of Services",
        });
    }, []);
    
    return (
        <Layout>
            
            <section className="breadcrumb-bg">
                <Container>
                    <h1 className="breadcrumb-title">Terms of Services</h1>
                    <Breadcrumb className="mt-2">
                        <Breadcrumb.Item><Link as={NavLink} to={HOME_PAGE_URL}>Home</Link></Breadcrumb.Item>
                        <Breadcrumb.Item active>Terms of Services</Breadcrumb.Item>
                    </Breadcrumb>
                </Container>
            </section>

            <section className="section-padding">
                <Container>
                    <Row className="align-items-center">
                        <Col>
                            <p className="mb-2">This website is operated by GLOBAL PHYSIO ACADEMY. GLOBAL PHYSIO ACADEMY offers all information and services available from this site to you, the user, conditioned upon your acceptance of all terms, conditions, policies and notices stated here.</p>
                            <p className="mb-2">By visiting our site and/ or purchasing something from us, you engage in our “Service” and agree to be bound by the following terms and conditions.</p>
                            <p className="mb-2">Please read these Terms of Service carefully before accessing or using our website or approaching to Global Physio Academy. By accessing or using any part of the site, you agree to be bound by these Terms of Service. If you do not agree to all the terms and conditions of this agreement, then you may not access the website or use any services.</p>
                            <p className="mb-4">We reserve the right to update, change or replace any part of these Terms of Service by posting updates and/or changes to our website. It is your responsibility to check this page periodically for changes.</p>
                        </Col>
                    </Row>
                    <Row className="align-items-center">
                        <Col>
                            <span className="terms">Terms & Conditions:</span>
                            <p className="mt-3 mb-2 terms-condition"><span>1.</span>All students agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the Service, use of the Service, or access to the Service or any contact from Global Physio Academy through which the service is provided, without express written permission by us.</p>
                            <p className="mb-2 terms-condition"><span>2.</span>All students agree not use a false e-mail address, pretend to be someone other than yourself, or otherwise mislead us or third-parties as to the origin of any comments.</p>
                            <p className="mb-2 terms-condition"><span>3.</span>Global Physio Academy reserve the right to refuse service to anyone for any reason at any time.</p>
                            <p className="mb-2 terms-condition"><span>4.</span>Global Physio Academy reserve the right to modify the contents of this site at any time, but we have no obligation to update any information on our site. You agree that it is your responsibility to monitor changes to our site.</p>
                            <p className="mb-2 terms-condition"><span>5.</span>Global Physio Academy do not warrant that the quality of any products, services, information, or other material purchased or obtained by you will meet your expectations, or that any errors in the Service will be corrected.</p>
                            <p className="mb-2 terms-condition"><span>6.</span>Global Physio Academy are not responsible if information made available on this site is not accurate, complete or current. The material on this site is provided for general information only and should not be relied upon or used as the sole basis for making decisions without consulting primary, more accurate, more complete or more timely sources of information. Any reliance on the material on this site is at your own risk.</p>
                            <p className="mb-2 terms-condition"><span>7.</span>Your submission of personal information at Global Physio Academy is governed by our Privacy Policy.</p>
                            <p className="mb-2 terms-condition"><span>8.</span>We do not warrant that the results that may be obtained from the use of the service will be accurate or reliable.</p>
                            <p className="mb-2 terms-condition"><span>9.</span>You expressly agree that your use of, or inability to use, the service is at your sole risk.</p>
                            <p className="mb-2 terms-condition"><span>10.</span>You agree that your comments will not violate any right of any third-party, including copyright, trademark, privacy, personality or other personal or proprietary right.</p>
                            <div>
                                <p className="mb-2 terms-condition"><span>11.</span>You may not use a false e-mail address, pretend to be someone other than yourself, or otherwise In addition to you are prohibited from using the site or its content.</p>
                                <ul>
                                    <li className="terms-subtext">1. To submit false or misleading information.</li>
                                    <li className="terms-subtext">2. To collect or track the personal information of others.</li>
                                </ul>
                            </div>
                            
                            <p className="mb-2 terms-condition"><span>12.</span>Global Physio Academy reserve the right to terminate your use of the Service or any related website for violating any of the prohibited uses.</p>
                        </Col>
                    </Row>
                </Container>
            </section>

        </Layout>
    );
}