import React, { useEffect, useState } from "react"; // eslint-disable-next-line
import { Breadcrumb, Col, Container, Row, Image, Button, Form, FormGroup, NavLink } from "react-bootstrap";
import Layout from "./layout.js";
import call_us_on from "../images/call-us.svg";
import contact_us_page_image from "../images/contact-us-page-image.png";
import WhatsApp_Us_icon from "../images/whatsapp-us-on.svg";
import mail_us_on from "../images/mail-us-on.svg";
import { Link } from "react-router-dom";
import { seo } from "../seo.js";
import { useForm } from "react-hook-form";
import axios from 'axios';
import * as Constants from '../constants';
import { ToastContainer, toast } from "react-toastify";
import {HOME_PAGE_URL} from "../constants";

export default function ContactUs() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    
    useEffect(() => {
        seo({
          title: "Contact Us",
        });
    }, []);

    useEffect(() => {
        document.querySelector("input[type='tel']").setAttribute("maxlength", "15");
      }, []);
    
    const [inputMobileValue, setInputMobileValue] = useState("");

    const handleMobileInputChange = (e) => {
        const value = e.target.value;
        const numericValue = value.trim().replace(/[^ +\d]/g, "");
        setInputMobileValue(numericValue);
      };

      const [isLoading, setIsLoading] = useState(false);
      useEffect(() => {
         if (isLoading) {
          document.body.classList.add("loader-showing");
        } else {
          document.body.classList.remove("loader-showing");
        }
      }, [isLoading]);
    
      const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
      } = useForm();

      const client = axios.create({
        baseURL: Constants.BACKEND_API_URL,
      });

      const onSubmit = (data) => {
        console.log(data);
        
        setIsLoading(true);

        const headers = {
            "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
            "Access-Control-Allow-Origin": "*",
        };

        client.post(
            "send-mail.php",
            { ...data, record_type: 'contact Us' },
            { headers: headers }
        ).then((response) => {
            // Handle the response from the server if needed
            setIsLoading(false);

            if (response.data.status_code == 1) {
                toast.success(response.data.message);
                reset();
                setInputMobileValue("");
                //document.getElementById("contact-us-form").reset();
            } else {
                toast.error(response.data.message);
            }
        }).catch((error) => {
            // Handle errors if the request fails
            toast.error(error);
        });

      };

    return (
        <Layout>
            {isLoading && (
                <div className="loader">
                <div className="loader--ring">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                </div>
            )}
            <section className="breadcrumb-bg">
                <Container>
                    <h1 className="breadcrumb-title">Contact Us</h1>
                    <Breadcrumb className="mt-2">
                        <Breadcrumb.Item><Link as={NavLink} to={HOME_PAGE_URL}>Home</Link></Breadcrumb.Item>
                        <Breadcrumb.Item active>Contact Us</Breadcrumb.Item>
                    </Breadcrumb>
                </Container>
            </section>
            <section className="bg2 section-padding">
                <Container>
                    <Row>
                        <Col lg={5}>
                            <div className="contact-left-block mb-4">
                                <div className="contact-icon">
                                    <Image src={call_us_on} alt="Call Us On"/>
                                </div>
                                <div className="contactus-detail">
                                    <span className="contact-title">Call us On</span>
                                    <div className="mb-0 mt-1 contact-numbers">
                                        <Link to={'tel:+919157884946'}>+91 91578 84946</Link>
                                        <span className="number-seperator">/</span>
                                        <Link to={'tel:+14168791986'}>+1 416 879 1986</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="contact-left-block mb-4">
                                <div className="contact-icon">
                                    <Image src={WhatsApp_Us_icon} alt="WhatsApp us On"/>
                                </div>
                                <div className="contactus-detail">
                                    <span className="contact-title">WhatsApp us On</span>
                                    <div className="mb-0 mt-1 contact-numbers">
                                        <Link to={'https://api.whatsapp.com/send?phone=919157884946'} target="_blank" rel="noreferrer">+91 91578 84946</Link>
                                        <span className="number-seperator">/</span>
                                        <Link to={'https://api.whatsapp.com/send?phone=4168791986'} target="_blank" rel="noreferrer">+1 416 879 1986</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="contact-left-block mb-4 mail-mobile">
                                <div className="contact-icon">
                                    <Image src={mail_us_on} alt="Mail us On"/>
                                </div>
                                <div className="contactus-detail">
                                    <span className="contact-title">Mail us On</span>
                                    <div className="mb-0 mt-1 contact-numbers">
                                        <Link to="mailto:globalphysioacademy@gmail.com">globalphysioacademy@gmail.com</Link>
                                    </div>
                                </div>
                            </div>
                            <Image src={contact_us_page_image} alt="Contact Us Image"  className="contactpage-image"/>
                        </Col>
                        <Col lg={7}>
                            <div className="contactpage-form">
                                <Row className="mb-4">
                                    <Col className="section-title">
                                        <span className="subtext">Get In Touch</span>
                                        <h2 className="section-heading">Send us a Message</h2>
                                    </Col>
                                </Row>
                                <Row>
                                    <Form onSubmit={handleSubmit(onSubmit)} id="contact-us-form" method="post">
                                        <FormGroup className="mb-2">
                                            <Form.Label>Name<span className="text-danger">*</span></Form.Label>
                                            <Form.Control type="text" placeholder="Name" name="name" {...register("name", { required: true , setValueAs: value => value.trim() })}></Form.Control>
                                            {errors.name && (
                                                <span className="text-danger">
                                                    Please Enter Name.
                                                </span>
                                                )}
                                        </FormGroup>
                                        <FormGroup className="mb-2">
                                            <Form.Label>Mobile<span className="text-danger">*</span></Form.Label>
                                            <Form.Control type="tel" placeholder="Mobile"
                                            minLength="8"
                                            maxLength="15"
                                            value={inputMobileValue}
                                            name="mobile"
                                            {...register("mobile", {
                                              required: "Please Enter Mobile.",
                                            })}
                                            onChange={handleMobileInputChange}
                                            ></Form.Control>
                                            {errors.mobile && (
                                                <span className="text-danger">
                                                {errors.mobile?.message}
                                                </span>
                                            )}
                                        </FormGroup>
                                        <FormGroup className="mb-2">
                                            <Form.Label>Email<span className="text-danger">*</span></Form.Label>
                                            <Form.Control type="email" placeholder="Email" 
                                            name="email"
                                            {...register("email", {
                                                required: "Please Enter Email.",
                                                pattern: {
                                                  value:
                                                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                                  message: "Please Enter Valid Email.",
                                                },
                                              })}
                                            ></Form.Control>
                                            {errors.email && (
                                                <p className="mb-0 text-danger">
                                                    {errors.email?.message}
                                                </p>
                                                )}
                                        </FormGroup>
                                        <FormGroup className="mb-2">
                                            <Form.Label>Subject</Form.Label>
                                            <Form.Control type="text" placeholder="Subject" name="subject" {...register('subject')}></Form.Control>
                                        </FormGroup>
                                        <Form.Group className="mb-4" controlId="exampleForm.ControlTextarea1">
                                            <Form.Label>Message</Form.Label>
                                            <Form.Control as="textarea" placeholder="Message" rows={3} name="message" {...register('message')}/>
                                        </Form.Group>
                                        <FormGroup className="mb-2 d-none">
                                            <Form.Label>Webname</Form.Label>
                                            <Form.Control type="text" placeholder="Webname" name="webname" {...register("webname", { required: false })}></Form.Control>
                                        </FormGroup>
                                        <Button variant="primary" type="submit" className="float-end"> Send </Button>
                                    </Form>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </Layout>
    );
}