import React from "react";
import { Routes, Route } from "react-router-dom";
import './App.css';
import Home from "./pages/home.js";
import AboutUs from "./pages/about-us";
import ContactUs from "./pages/contact-us";
import SuccessStories from "./pages/success-stories";
import PceWrittenPreparation from "./pages/pce-written-preparation";
import NptePreparation from "./pages/npte-preparation";
import * as Constants from "./constants";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import "./css/style.css";
import Error404 from "./pages/404-error";
import TermsOfServices from "./pages/terms-of-services";

function App() {

  return (
    <div className="App">
      <Routes>
      	<Route path={Constants.LANDING_PAGE_URL} element={<Home />} />
      	<Route path={Constants.INDEX_PAGE_URL} element={<Home />} />
		<Route path={Constants.HOME_PAGE_URL} element={<Home />} />
        <Route path={Constants.ABOUT_US_PAGE_URL} element={<AboutUs />} />
        <Route path={Constants.CONTACT_US_PAGE_URL} element={<ContactUs />} />
        <Route path={Constants.SUCCESS_STORIES_PAGE_URL} element={<SuccessStories />} />
        <Route path={Constants.PCE_WRITTEN_PREPARATION_PAGE_URL} element={<PceWrittenPreparation />} />
        <Route path={Constants.NPTE_PREPARATION_PAGE_URL} element={<NptePreparation />} />
        <Route path={Constants.TERMS_OF_SERVICE_PAGE_URL} element={<TermsOfServices />} />
        <Route path="*" element={<Error404 />} />
      </Routes>
    </div>
  );
}

export default App;
