import React from "react";
import { useState } from "react";
import { Container, Navbar, Offcanvas, Nav, Row, Col, Image, NavDropdown } from "react-bootstrap";
import { NavLink, Link } from 'react-router-dom';
import header_logo from "../../images/header_logo.png";
import GetStartedModal from "../../components/get-started-modal";
import { ABOUT_US_PAGE_URL, CONTACT_US_PAGE_URL, HOME_PAGE_URL, NPTE_PREPARATION_PAGE_URL, PCE_WRITTEN_PREPARATION_PAGE_URL, SUCCESS_STORIES_PAGE_URL } from "../../constants";
import Download20QuestionsModal from "../../components/Download-20-question-pce-modal";

export default function Header() {
  // if($(window).innerWidth() <= 992) {
    const [show, setShow] = useState(false);
    const showDropdown = (e)=>{
        setShow(!show);
    }
    const hideDropdown = e => {
        setShow(false);
    }

    const toggleDropdown = () => {
      setShow(!show);
    };

    const toggleNav = () => {
      document.body.classList.toggle("nav-slide-open");
    };

    document.addEventListener("click", function(e) {
      if (window.innerWidth < 992 && !e.target.closest("#slide-toggle")) {
        document.body.classList.remove("nav-slide-open");
      }
    });
  // }

    return (
      <>
      <div className="top-header">
        <Container>
          <Row>
            <Col className="top-header-links-left">
              <span>DOWNLOAD 20 QUESTION PCE SAMPLE QUIZ FOR FREE</span>
              <Download20QuestionsModal />
            </Col>
            <Col className="top-header-links-right">
              <i className="fi fi-brands-whatsapp"></i>
              <Link to={'https://api.whatsapp.com/send?phone=919157884946'} className="topbar-contact" target="_blank" rel="noreferrer"> +91 91578 84946</Link>
              <span>/</span>
              <Link to={'https://api.whatsapp.com/send?phone=14168791986'} className="topbar-contact" target="_blank" rel="noreferrer"> +1 416 879 1986</Link>
            </Col>
          </Row>
        </Container>
      </div>
        <Navbar expand="lg" className="main-navbar bg-body-tertiary m-0 p-0" sticky="top">
          <Container>
            <Link to={HOME_PAGE_URL} className="pt-1 pb-1">
              <Navbar.Brand className="m-0"><Image src={header_logo} alt="Global Physio Academy" /></Navbar.Brand>
            </Link>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" className="btn navbar-toggler border-0" id="slide-toggle" onClick={toggleNav}>
            <span className="navbar-dash" /><span className="navbar-dash" /><span className="navbar-dash" />
            </Navbar.Toggle>
            <Navbar.Offcanvas placement="start">
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 navlinks">
                  <Nav.Link as={NavLink} to={HOME_PAGE_URL}>Home</Nav.Link>
                  <Nav.Link as={NavLink} to={ABOUT_US_PAGE_URL}>About Us</Nav.Link>
                  <NavDropdown onClick={toggleDropdown} title={ <span> Courses <i className="fi fi-rr-angle-small-down"></i> </span> } onMouseEnter={showDropdown} onMouseLeave={hideDropdown} show={show} className="dropdown-main-heading">
                    <NavDropdown.Item as={NavLink} to={PCE_WRITTEN_PREPARATION_PAGE_URL}>PCE Written Preparation</NavDropdown.Item>
                    <NavDropdown.Item as={NavLink} to={NPTE_PREPARATION_PAGE_URL}>NPTE Preparation</NavDropdown.Item>
                  </NavDropdown>
                  <Nav.Link as={NavLink} to={SUCCESS_STORIES_PAGE_URL}>Success Stories</Nav.Link>
                  <Nav.Link as={NavLink} to={CONTACT_US_PAGE_URL}>Contact Us</Nav.Link>
                </Nav>
                <GetStartedModal />
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
    </>
    );
}