import React, { useEffect} from "react"; // eslint-disable-next-line
import { Col, Container, Image, Row, Breadcrumb, NavLink } from "react-bootstrap";
import Layout from "./layout.js";
import mentor from "../images/mamta-kansara.png";
import mission from "../images/mission.svg";
import vision from "../images/vision.svg";
import values from "../images/values.svg";
import { seo } from "../seo.js";
import { HOME_PAGE_URL } from "../constants.js";
import { Link } from "react-router-dom";

export default function AboutUs() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    
    useEffect(() => {
        seo({
          title: "About Us",
        });
    }, []);
    
    return (
        <Layout>
            
            <section className="breadcrumb-bg">
                <Container>
                    <h1 className="breadcrumb-title">About Us</h1>
                    <Breadcrumb className="mt-2">
                        <Breadcrumb.Item><Link as={NavLink} to={HOME_PAGE_URL}>Home</Link></Breadcrumb.Item>
                        <Breadcrumb.Item active>About Us</Breadcrumb.Item>
                    </Breadcrumb>
                </Container>
            </section>

            <section className="section-padding">
                <Container>
                    <Row className="titles">
                        <Col className="section-title">
                            <span className="subtext">About Us</span>
                            <h2 className="section-heading">Global Physio Academy</h2>
                        </Col>
                    </Row>
                    <Row className="align-items-center">
                        <Col lg={4} md={6} sm={6} className="mentor-block">
                            <Image src={mentor} alt="Mamta Kansara" className="mentor-image" rounded />
                            <div className="mentor">
                                <h3>Mamta Kansara</h3>
                                <p>Registered Physiotherapist</p>
                            </div>
                        </Col>
                        <Col lg={8} md={6} sm={12} className="mentor-content">
                            <div className="mentor-details">
                                <p className="mb-2"><span>Mamta Kansara</span> started PCE written/ NPTE prep course - Global Physio Academy with her great effort and full determination. She started with few students and now getting involved with great group of students and only one focus - quality of education to clear PCE Written/ NPTE course.</p>
                                <p className="mb-2">She believes in self confidence, full concentration and firm determination. Every student is unique so identify everyone weakness and focus on their target area to help them to get success in PCE written or NPTE exam.</p>
                            </div>
                            <div className="approach mt-4">
                                <h4>Global physio Academy Approach for every students:</h4>
                                <p className="mb-3 emphasize">Emphasize on three components for Written Exam :</p>
                                <div className="approach-list d-flex mb-4">
                                    <span>Conceptual Analysis</span>
                                    <span>Critical Clinical Thinking</span>
                                    <span>Logical Reasoning</span>
                                </div>
                                <p className="individual-plan">Students will be able to learn through innovative, individualized study plans developed by <span>Mamta Kansara</span> to gain maximum out of all the courses offered.</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="mission-vision-values section-padding bg-color2">
                <Container>
                    <Row className="titles">
                        <Col className="section-title">
                            <span className="subtext">Global Physio Academy</span>
                            <h2 className="section-heading">Mission - Vision - Values</h2>
                        </Col>
                    </Row>
                    <Row className="mvv-blocks">
                        <Col lg={4} sm={6} className="mvv-single">
                            <div className="mvv">
                                <Image src={mission} alt="Mission"  className="mvv-image"/>
                                <span className="mb-2 text-center">Mission</span>
                                <p className="text-center">Our mission is to help students pass their licensure exam with great success.</p>
                            </div>
                        </Col>
                        <Col lg={4} sm={6} className="mvv-single">
                            <div className="mvv">
                                <Image src={vision} alt="Vision"  className="mvv-image"/>
                                <span className="mb-2 text-center">Vision</span>
                                <p className="text-center">Our vision is to get 100% pass result of all of our students every time.</p>
                            </div>
                        </Col>
                        <Col lg={4} sm={6} className="mvv-single">
                            <div className="mvv">
                                <Image src={values} alt="Values"  className="mvv-image"/>
                                <span className="mb-2 text-center">Values</span>
                                <p className="text-center">Our values are Self Confidence, Concentration and Firm Determination.</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

        </Layout>
    );
}