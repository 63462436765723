import React, { useEffect} from "react"; // eslint-disable-next-line
import { Col, Container, Image, Row } from "react-bootstrap";
import Layout from "./layout.js";
import { seo } from "../seo.js";
import Page_Not_Found from "../images/404-error.svg"
import { Link } from "react-router-dom";
import { HOME_PAGE_URL } from "../constants.js";

export default function Error404() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    
    useEffect(() => {
        seo({
          title: "Page Not Found",
        });
    }, []);
    
    return (
        <Layout>
            <section className="section-padding">
                <Container>
                    <Row>
                        <Col className="text-center pagenotfound">
                            <Image src={Page_Not_Found} alt="404 Error" />
                        </Col>
                    </Row>
                    <Row>
                        <Col className="back-to-home">
                            <Link to={HOME_PAGE_URL} className="btn btn-primary">Back to Home</Link>
                        </Col>
                    </Row>
                </Container>
            </section>
        </Layout>
    );
}