import React, { useEffect} from "react"; // eslint-disable-next-line
import { useState } from 'react';
import OwlCarousel from 'react-owl-carousel';
import { Button, Card, Col, Container, Image, Row } from "react-bootstrap";
import Layout from "./layout.js";
import quote from "../images/quote.png";
import wcu1 from "../images/wcu-1.svg";
import wcu2 from "../images/wcu-2.svg";
import wcu3 from "../images/wcu-3.svg";
import wcu4 from "../images/wcu-4.svg";
import mentor from "../images/mamta-kansara.png";
import CountUp from 'react-countup';
import counter_image from "../images/counter-image.png";
import course1 from "../images/course1.png";
import course2 from "../images/course2.png";
import course_video_modal_image from "../images/course-video-modal-image.png";
import { seo } from "../seo.js";
import { Link } from "react-router-dom";
import { ABOUT_US_PAGE_URL, NPTE_PREPARATION_PAGE_URL, PCE_WRITTEN_PREPARATION_PAGE_URL, SUCCESS_STORIES_PAGE_URL } from "../constants.js";
import Modal from 'react-bootstrap/Modal';
import youtube from "../images/youtube.svg";
import pdf_icon from "../images/pdf-icon.svg";
import Step_by_Step_Guide_For_PDE_Credential from "../images/pdf/Step-by-Step-Guide-For-PDE-Credential.pdf"
import GetStartedModal from "../components/get-started-modal.js";
import angle_right from "../images/angle-small-right-black.svg";

export default function Home() {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    
    useEffect(() => {
        seo({
          title: "Home",
        });
    }, []);
  
    return (
        <Layout>
            <section className="banner-image">
                <Container className="">
                    <div className="banner">
                        <span className="banner-title">Everything you need to <span>succeed</span> in the <span>PCE/NPTE at one stop</span>.</span>
                        <GetStartedModal />
                    </div>
                </Container>
            </section>

            <section className="section-padding">
                <Container>
                    <Row>
                        <Col lg={8} className="counter-left">
                            <Row className="counter-single-block mb-3">
                                <Col lg={3} className="counter-block">
                                    <CountUp end={100} suffix="+" className="counter-number" />
                                </Col>
                                <Col lg={9} className="counter-content">
                                    <span>Students</span>
                                    <p>Mamta Kansara is continuous with academic field since 2011 and contribute her endless efforts to provide excellent teaching to physiotherapy students. More than 100 plus students are enrolled with Global Physio Academy for their PCE and NPTE exam preparation and many more are warmly welcomed here.</p>
                                </Col>
                            </Row>
                            <Row className="counter-single-block mb-3">
                                <Col lg={3} className="counter-block">
                                    <CountUp end={100} suffix="%" className="counter-number" />
                                </Col>
                                <Col lg={9} className="counter-content">
                                    <span>Passionate Teaching</span>
                                    <p>Mamta Kansara is passionate about teaching with full dedication. She focus on each students with active teaching and developing critical reasoning skills among students. Each course starts with explanation of blueprint of PCE/ NPTE syllabus.</p>
                                </Col>
                            </Row>
                            <Row className="counter-single-block mb-3">
                                <Col lg={3} className="counter-block">
                                    <CountUp end={1} suffix="" className="counter-number" />
                                </Col>
                                <Col lg={9} className="counter-content">
                                    <span>One Goal - Pass Result For Students</span>
                                    <p>Global Physio Academy work with only one goal to achieve success result for their students. Our courses includes theory classes, detailed manuals, mentoring with questions and answers, recording for revision, practice exams, Ethic bundles and many more to get only one Goal:- Pass result for students.</p>
                                </Col>
                            </Row>
                            <Row className="counter-single-block">
                                <Col lg={3} className="counter-block">
                                    <CountUp end={1000} suffix="+" className="counter-number" />
                                </Col>
                                <Col lg={9} className="counter-content">
                                    <span>Questions & Answers</span>
                                    <p>Practice with Vignette style questions and get idea about co-relation of various topics under domain of PCE and those who are NPTE takers, standalone questions with complete rationales. You will get clear and most dedicated study strategies planned for every individual.</p>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={4} className="counter-right">
                            <Row className="white-titles mb-4">
                                <Col className="section-title">
                                    <span className="subtext"><h1>Global Physio Academy</h1></span>
                                    <h2 className="section-heading">PCE Written & NPTE (USA) Preparation Classes</h2>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Image src={counter_image} alt="Counter Image" className="counter-image"/>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="why-choose-us section-padding">
                <Container>
                    <Row className="white-titles">
                        <Col className="section-title">
                            <span className="subtext">Why Choose Us?</span>
                            <h2 className="section-heading">Be successful on your first attempt</h2>
                        </Col>
                    </Row>
                    <Row className="wcu-blocks">
                        <Col lg={3} sm={6} className="">
                            <div className="wcu">
                                <Image src={wcu1} alt="Explanation about PCE & PCE blueprint/ NPTE"  className="mb-4"/>
                                <h3 className="mb-2 text-center">01</h3>
                                <p className="text-center">Explanation about PCE & PCE blueprint/ NPTE</p>
                            </div>
                        </Col>
                        <Col lg={3} sm={6} className="">
                            <div className="wcu">
                                <Image src={wcu2} alt="Canadian experienced Physiotherapist"  className="mb-4"/>
                                <h3 className="mb-2 text-center">02</h3>
                                <p className="text-center">Canadian experienced Physiotherapist</p>
                            </div>
                        </Col>
                        <Col lg={3} sm={6} className="">
                            <div className="wcu">
                                <Image src={wcu3} alt="Limited student batch"  className="mb-4 mleft-auto"/>
                                <h3 className="mb-2 text-center">03</h3>
                                <p className="text-center">Limited student batch</p>
                            </div>
                        </Col>
                        <Col lg={3} sm={6} className="">
                            <div className="wcu">
                                <Image src={wcu4} alt="Feedback and full concentration on students as per their needs"  className="mb-4"/>
                                <h3 className="mb-2 text-center">04</h3>
                                <p className="text-center">Feedback and full concentration on students as per their needs</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="bg-color2 section-padding course-section">
                <Container>
                    <Row className="white-titles">
                        <Col className="section-title">
                            <span className="subtext">Courses</span>
                            <h2 className="section-heading">Prepare for success on your Written PCE</h2>
                        </Col>
                    </Row>
                    <Row className="courses-blocks">
                        <Col lg={4} sm={6} className="course-single">
                            <Card>
                                <Card.Img src={course1} alt="course1" />
                                <Card.ImgOverlay>
                                    <Card.Title className="mb-3">PCE Written Preparation</Card.Title>
                                    <Link to={PCE_WRITTEN_PREPARATION_PAGE_URL} className="btn btn-primary card-link">Explore Course</Link>
                                </Card.ImgOverlay>
                            </Card>
                        </Col>
                        <Col lg={4} sm={6} className="">
                            <Card>
                                <Card.Img src={course2} alt="course1" />
                                <Card.ImgOverlay>
                                    <Card.Title className="mb-3">NPTE Preparation</Card.Title>
                                    <Link to={NPTE_PREPARATION_PAGE_URL} className="btn btn-primary card-link">Explore Course</Link>
                                </Card.ImgOverlay>
                            </Card>
                        </Col>
                        <Col lg={4} sm={6} className="">
                            <Card>
                                <Card.Img src={course_video_modal_image} alt="course1" />
                                <Card.ImgOverlay>
                                    <Card.Title className="mb-3">PCE Credential</Card.Title>
                                    <Link className="btn btn-primary card-link" onClick={handleShow}>Explore More</Link>
                                </Card.ImgOverlay>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="section-padding">
                <Container>
                    <Row className="titles">
                        <Col className="section-title">
                            <span className="subtext">About Us</span>
                            <h2 className="section-heading">Global Physio Academy</h2>
                        </Col>
                        <Col className="viewall-block">
                            <Link to={ABOUT_US_PAGE_URL} className="view-all">Know More
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M18 12.0001C17.9951 11.474 17.7832 10.971 17.41 10.6001L13.12 6.30007C12.9326 6.11382 12.6792 6.00928 12.415 6.00928C12.1508 6.00928 11.8974 6.11382 11.71 6.30007C11.6163 6.39303 11.5419 6.50363 11.4911 6.62549C11.4403 6.74735 11.4142 6.87806 11.4142 7.01007C11.4142 7.14208 11.4403 7.27279 11.4911 7.39465C11.5419 7.51651 11.6163 7.62711 11.71 7.72007L15 11.0001H5C4.73478 11.0001 4.48043 11.1054 4.29289 11.293C4.10536 11.4805 4 11.7349 4 12.0001C4 12.2653 4.10536 12.5196 4.29289 12.7072C4.48043 12.8947 4.73478 13.0001 5 13.0001H15L11.71 16.2901C11.5217 16.477 11.4154 16.7312 11.4144 16.9965C11.4135 17.2619 11.518 17.5168 11.705 17.7051C11.892 17.8934 12.1461 17.9997 12.4115 18.0006C12.6768 18.0016 12.9317 17.897 13.12 17.7101L17.41 13.4101C17.7856 13.0367 17.9978 12.5296 18 12.0001Z" fill="#FBA115"/>
                                </svg>
                            </Link>
                        </Col>
                    </Row>
                    <Row className="align-items-center">
                        <Col lg={4} md={6} sm={6} className="mentor-block">
                            <Image src={mentor} alt="Mamta Kansara" className="mentor-image" rounded />
                            <div className="mentor">
                                <h3>Mamta Kansara</h3>
                                <p>Registered Physiotherapist</p>
                            </div>
                        </Col>
                        <Col lg={8} md={6} sm={12} className="mentor-content">
                            <div className="mentor-details">
                                <p className="mb-2"><span>Mamta Kansara</span> started PCE written/ NPTE prep course - Global Physio Academy with her great effort and full determination. She started with few students and now getting involved with great group of students and only one focus - quality of education to clear PCE Written/ NPTE course.</p>
                                <p className="mb-2">She believes in self confidence, full concentration and firm determination. Every student is unique so identify everyone weakness and focus on their target area to help them to get success in PCE written or NPTE exam.</p>
                            </div>
                            <div className="approach mt-4">
                                <h4>Global Physio Academy Approach for every students:</h4>
                                <p className="mb-3 emphasize">Emphasize on three components for Written Exam :</p>
                                <div className="approach-list d-flex mb-4">
                                    <span>Conceptual Analysis</span>
                                    <span>Critical Clinical Thinking</span>
                                    <span>Logical Reasoning</span>
                                </div>
                                <p className="individual-plan">Students will be able to learn through innovative, individualized study plans developed by <span>Mamta Kansara</span> to gain maximum out of all the courses offered.</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="bg-color2 section-padding">
                <Container className="">
                    <Row className="titles">
                        <Col className="section-title" lg={8}>
                            <span className="subtext">Success Stories</span>
                            <h2 className="section-heading">We've helped thousands of students pass the PCE Written and NPTE Exams</h2>
                        </Col>
                        <Col className="viewall-block" lg={4}>
                            <Link to={SUCCESS_STORIES_PAGE_URL} className="view-all">View All
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M18 12.0001C17.9951 11.474 17.7832 10.971 17.41 10.6001L13.12 6.30007C12.9326 6.11382 12.6792 6.00928 12.415 6.00928C12.1508 6.00928 11.8974 6.11382 11.71 6.30007C11.6163 6.39303 11.5419 6.50363 11.4911 6.62549C11.4403 6.74735 11.4142 6.87806 11.4142 7.01007C11.4142 7.14208 11.4403 7.27279 11.4911 7.39465C11.5419 7.51651 11.6163 7.62711 11.71 7.72007L15 11.0001H5C4.73478 11.0001 4.48043 11.1054 4.29289 11.293C4.10536 11.4805 4 11.7349 4 12.0001C4 12.2653 4.10536 12.5196 4.29289 12.7072C4.48043 12.8947 4.73478 13.0001 5 13.0001H15L11.71 16.2901C11.5217 16.477 11.4154 16.7312 11.4144 16.9965C11.4135 17.2619 11.518 17.5168 11.705 17.7051C11.892 17.8934 12.1461 17.9997 12.4115 18.0006C12.6768 18.0016 12.9317 17.897 13.12 17.7101L17.41 13.4101C17.7856 13.0367 17.9978 12.5296 18 12.0001Z" fill="#FBA115"/>
                                </svg>
                            </Link>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <OwlCarousel className="owl-theme"
                                loop={true} 
                                margin={30} 
                                autoplay ={true}
                                center={false}
                                responsive={{
                                    '0': { items: 1, nav: false, dotsEach: 3, dotData: true },
                                    '768': { items: 2, nav: false, dotsEach: 3, dotData: true },
                                    '992': { items: 3, nav: false, dotsEach: 3, dotData: true }
                                }}>
                                <div className='item'>
                                    <p className="testimonial-content truncate">I passed PCE exam in first attempt and its never be possible without My guide, teacher Mamta Kansara. I pass this exam from India --initially I was very much confuse how to take exam from where to prepare but after contacting Global Physio and Mamta it went smoothly with all the process to the day, hour and minute of exam. A to Z topics about PCE   written exam is covered from Mamta Kansara. Her live lectures help me a lot and her way of teaching is excellent for every concepts it clears all my doubts. Mock exams from her help me lot to pass this exam. ----MOST RECOMMENDED AND TRULY GUIDED ACADEMY.</p>
                                    <div className="client-details mt-3">
                                        <span className="name-initial">M</span>
                                        <div className="client-detail">
                                            <h5>Mohammad</h5>
                                            <div className="ratings">
                                                <span>5.0</span>
                                                <i className="fi fi-sr-star"></i>
                                                <i className="fi fi-sr-star"></i>
                                                <i className="fi fi-sr-star"></i>
                                                <i className="fi fi-sr-star"></i>
                                                <i className="fi fi-sr-star"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <Image src={quote} alt="quote" className="quote" />
                                </div>
                                <div className='item'>
                                    <p className="testimonial-content truncate">"Self-belief and Hard work will always earn you success.” I am extremely happy to announce that I cleared PCE written component. Yes, the word “PASSED” seems like a miracle when it comes after long wait and hard work. It wouldn't have been possible without proper guidance of Mamta Kansara. All the lectures, materials, notes and exams helped a lot to cover the contents. She always helped me identifying my weakness and pushed me to work on it. And that's the only reason I could finally make it to the shining path. Thank you for always being there for me, you master mind---ONE AND ONLY MAMTA KANSARA.</p>
                                    <div className="client-details mt-3">
                                        <span className="name-initial">N</span>
                                        <div className="client-detail">
                                            <h5>Nikita</h5>
                                            <div className="ratings">
                                                <span>5.0</span>
                                                <i className="fi fi-sr-star"></i>
                                                <i className="fi fi-sr-star"></i>
                                                <i className="fi fi-sr-star"></i>
                                                <i className="fi fi-sr-star"></i>
                                                <i className="fi fi-sr-star"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <Image src={quote} alt="quote" className="quote" />
                                </div>
                                <div className='item'>
                                    <p className="testimonial-content truncate">Now is my time to shine! I can finally announce to the world that I am a resident physical therapist, and the best part is that I passed on my first try. Mamta Ma'am was crucial in assisting me in achieving a major life objective during my  exam preparation process. So, ma'am, please accept my sincere gratitude for your tireless efforts and unceasing commitment on my behalf. I can well recall the time when I wanted to take a test but had not yet begun my preparation. You are the one who inspired me and made me feel confident that even I could succeed! Despite the fact that I called you at 11:00 PM with an endless list of questions, you never seemed unhappy, and you always provided an answer. You are not only my instructor; you are also a part of my family who I can rely on without hesitation. When necessary, you were able to be tough, but you were also kind and compassionate.---EVERYONE MUST STOP AT GLOBAL PHYSIO TO CLEAR PCE WRITTEN EXAM.</p>
                                    <div className="client-details mt-3">
                                        <span className="name-initial">D</span>
                                        <div className="client-detail">
                                            <h5>Dhara</h5>
                                            <div className="ratings">
                                                <span>5.0</span>
                                                <i className="fi fi-sr-star"></i>
                                                <i className="fi fi-sr-star"></i>
                                                <i className="fi fi-sr-star"></i>
                                                <i className="fi fi-sr-star"></i>
                                                <i className="fi fi-sr-star"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <Image src={quote} alt="quote" className="quote" />
                                </div>
                                <div className='item'>
                                    <p className="testimonial-content truncate">I was desperately waiting for to see only 4 letters ‘‘Pass”. By god’s grace and blessings of my family finally I passed PCE. The biggest role played by Mamta Kansara ma'am who was not only my teacher but also she was my guide, friend, motivator. She is one woman army. Her live lectures are so helpful. Also I had to be ready for her rapid fire questions at any time but that kind of revision played vital role in my revision. On top of that her practice exams, ethics classes and daily questions are very helpful. She was the one I can call her any time and she answered my questions all the times.---THANK YOU FOR EVERYTHING.</p>
                                    <div className="client-details mt-3">
                                        <span className="name-initial">T</span>
                                        <div className="client-detail">
                                            <h5>Trusha</h5>
                                            <div className="ratings">
                                                <span>5.0</span>
                                                <i className="fi fi-sr-star"></i>
                                                <i className="fi fi-sr-star"></i>
                                                <i className="fi fi-sr-star"></i>
                                                <i className="fi fi-sr-star"></i>
                                                <i className="fi fi-sr-star"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <Image src={quote} alt="quote" className="quote" />
                                </div>
                                <div className='item'>
                                    <p className="testimonial-content truncate">Finally my time to write the story passed my PCE exam. I would like to thank to Global Physio Academy and Mamta ma'am who was there for me all the time supporting me personally and professionally. Her lectures are very well explained in details and she is just phone call away whenever I needed her, multiple revisions and never said no to anything. Thank you once again it was not possible without you.-PCE WRITTEN AND I THINK PCE WITH MAMTA KANSARA IS BOTH SYNONYMOUS.</p>
                                    <div className="client-details mt-3">
                                        <span className="name-initial">P</span>
                                        <div className="client-detail">
                                            <h5>Pooja</h5>
                                            <div className="ratings">
                                                <span>5.0</span>
                                                <i className="fi fi-sr-star"></i>
                                                <i className="fi fi-sr-star"></i>
                                                <i className="fi fi-sr-star"></i>
                                                <i className="fi fi-sr-star"></i>
                                                <i className="fi fi-sr-star"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <Image src={quote} alt="quote" className="quote" />
                                </div>
                                <div className='item'>
                                    <p className="testimonial-content truncate">With proud moment and by God's grace I proudly say we met MAMTA KANSARA--GREAT PERSONALITY AND EVERREADY BATTERY WOMAN. The moment we started our preparation until our exam she is all time present beside us with only 1 phone call away. When I see PASS on my screen and that not only me ---my wife also pass the exam--both of us clear with first attempt. ---WONDERFUL ACHIEVEMENT. If I am writing all her capability perhaps it takes few pages to write--so much clear idea, way of preparation and her too the point lecture prepares us with clear concept. We have not enough words for her constant support and motivation all throughout our journey. MSK--NEURO--CARDIO--PULMONARY--OTHER SYSTEM--PEDIA--ETHICS--ALL IN ONE---PCE WITH MAMTA. It is so easy to be with 1 person who teaches us, guides us and knows our capacity where we are! MENTORING----BEST portion of her class which makes us to think with co-relation of topic. GLOBAL PHYSIO==MAMTA KANSARA==PASSED PCE WRITTEN FOR US.</p>
                                    <div className="client-details mt-3">
                                        <span className="name-initial">M</span>
                                        <div className="client-detail">
                                            <h5>Mohan and Nisha</h5>
                                            <div className="ratings">
                                                <span>5.0</span>
                                                <i className="fi fi-sr-star"></i>
                                                <i className="fi fi-sr-star"></i>
                                                <i className="fi fi-sr-star"></i>
                                                <i className="fi fi-sr-star"></i>
                                                <i className="fi fi-sr-star"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <Image src={quote} alt="quote" className="quote" />
                                </div>
                                <div className='item'>
                                    <p className="testimonial-content truncate">With the blessings of God, I successfully passed my PCE written component on third attempt. I want to express my heartfelt gratitude to Mamta Kansara for her invaluable support in achieving this milestone. I found that ma’am had answers to all my questions always and this made me believe she is a walking Encyclopedia for sure. I realized that her concepts are backed up with evidence from textbooks. This being my third attempt, I always felt anxious and stressed about the exams, however, the way she counselled me and guided me through the process help me not only prepare well but also keep my calm during the exam. It was due to the confidence she instilled in me that I was able to complete my paper before time.</p>
                                    <div className="client-details mt-3">
                                        <span className="name-initial">R</span>
                                        <div className="client-detail">
                                            <h5>Rohit</h5>
                                            <div className="ratings">
                                                <span>5.0</span>
                                                <i className="fi fi-sr-star"></i>
                                                <i className="fi fi-sr-star"></i>
                                                <i className="fi fi-sr-star"></i>
                                                <i className="fi fi-sr-star"></i>
                                                <i className="fi fi-sr-star"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <Image src={quote} alt="quote" className="quote" />
                                </div>
                                <div className='item'>
                                    <p className="testimonial-content truncate">After experiencing the disappointment of failing my important license exam on the first try, I channeled my determination into rigorous preparation. With focused study and mentorship from Global Physio Academy, I honed my understanding of the material. With continued support of my family and the proper guidance from Mamta Ma’am and her discussion sessions, I succeeded in my second attempt with flying colours.</p>
                                    <div className="client-details mt-3">
                                        <span className="name-initial">J</span>
                                        <div className="client-detail">
                                            <h5>Jesica</h5>
                                            <div className="ratings">
                                                <span>5.0</span>
                                                <i className="fi fi-sr-star"></i>
                                                <i className="fi fi-sr-star"></i>
                                                <i className="fi fi-sr-star"></i>
                                                <i className="fi fi-sr-star"></i>
                                                <i className="fi fi-sr-star"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <Image src={quote} alt="quote" className="quote" />
                                </div>
                            </OwlCarousel>
                        </Col>
                    </Row>
                </Container>
            </section>
            
            <section>
                <Modal show={show} size="lg" onHide={handleClose} className="video-modal">
                    <Modal.Header closeButton>
                    <Modal.Title className="video-modal-main-title">PCE CREDENTIAL</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Container>
                            <Row>
                                <Col lg={6} md={12} sm={12}>
                                    <ul className="video-modal">
                                        <li><Image src={angle_right} className="" /> Credential evaluation—CAPR</li>
                                        <li><Image src={angle_right} className="" /> As a foreign-trained physical therapist in the CANADA, you need to provide evidence that your education is substantially equivalent to a physical therapist trained in the CANADA.</li>
                                        <li><Image src={angle_right} className="" /> This process involves submitting your academic documents such as your transcripts, Marksheet syllabus, etc. to a CAPR.</li>
                                        <li><Image src={angle_right} className="" /> Here is YouTube link to get idea about PCE Credential.</li>
                                    </ul>
                                </Col>
                                <Col lg={6} md={12} sm={12}>
                                    <Link to={'https://www.youtube.com/watch?v=tVCuwt6GgmM'} className="youtube" target="_blank" rel="noopener">
                                        <Image src={'http://img.youtube.com/vi/tVCuwt6GgmM/hqdefault.jpg'} alt="YouTube Thumbnail" className="youtube-thumbnail"></Image>
                                        <Image src={youtube} alt="YouTube Icon" className="youtube-icon"></Image>
                                    </Link>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="popup-pdf-block mt-3">
                                        <a href={Step_by_Step_Guide_For_PDE_Credential} className="popup-pdf text-center" target="_blank" rel="noopener"><Image src={pdf_icon} alt="Step by Step Guide For PCE Credential" />Step by Step Guide For PCE Credential</a>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                    <Button onClick={handleClose}>
                        Close
                    </Button>
                    </Modal.Footer>
                </Modal>
            </section>

        </Layout>
    );
}